<template>
	<div class="relative selection:bg-mx-orange selection:text-white">
		<!-- Default Label -->
		<label
			v-if="!floatingLabel && !hideLabel"
			:for="id"
		>
			<slot name="label">
				<FormLabel
					v-if="label?.length"
					:class="[
						labelSize,
						labelClass,
						'min-h-5'
					]"
				>
					{{ label }}
					<span
						v-if="required && showRequiredAstrisk"
						:class="[
							labelSize,
							'text-red-500 lowercase'
						]"
					>
						*
					</span>
				</FormLabel>
			</slot>
		</label>
		<div class="relative">
			<slot name="input">
				<!-- Floating Label | Standard Text Inputs -->
				<div
					v-if="floatingLabel"
					class="relative"
				>
					<input
						v-if="isStandardTextInput"
						:id="id"
						class="w-full text-black placeholder-transparent outline-none appearance-none placeholder:select-none peer autofill:bg-mx-orange focus:ring-0 focus:outline-none focus:border-t-transparent focus:border-x-transparent border-t-transparent border-x-transparent"
						:type="dynamicInputType"
						:class="[
							inputClass,
							borderValidationClass,
							readOnly ? 'border-b-0' : borderClass,
							{ 'rounded-lg' : rounded },
							{ 'bg-gray-200' : disabled },
							{ 'pl-8' : displayIcon && iconPosition === LEFT },
							`focus:ring-${ringColor}`,
							bgColor,
							showErrorMessage ? 'invalid:border-b-red-500' : 'focus:border-b-mx-orange'
						]"
						:autocomplete="autocomplete"
						:maxlength="60"
						:placeholder="placeholder"
						:value="modelValue"
						:disabled="disabled"
						:readonly="readOnly"
						:required="required"
						@input="updateValue"
						@blur="handleBlur"
						@focus="handleFocus"
						@keyup="handleKeyup"
					>

					<!-- Floating Label | Telephone Input -->
					<input
						v-if="inputType === TEL"
						:id="id"
						:value="phoneNumber"
						:type="TEL"
						class="w-full text-black placeholder-transparent outline-none appearance-none placeholder:select-none peer autofill:bg-mx-orange focus:ring-0 focus:outline-none focus:border-t-transparent focus:border-x-transparent border-t-transparent border-x-transparent"
						:class="[
							inputClass,
							borderValidationClass,
							readOnly ? 'border-b-0' : borderClass,
							{ 'rounded-lg' : rounded },
							{ 'bg-gray-200' : disabled },
							{ 'pl-8' : displayIcon && iconPosition === LEFT },
							`focus:ring-${ringColor}`,
							bgColor,
							showErrorMessage ? 'invalid:border-b-red-500' : 'focus:border-b-mx-orange'
						]"
						:autocomplete="autocomplete"
						:maxlength="25"
						:placeholder="placeholder"
						:disabled="disabled"
						:readonly="readOnly"
						:required="required"
						@blur="handleBlur"
						@input="updateValue"
						@focus="handleFocus"
						@keyup="handleKeyup"
					>

					<!-- Floating Label | Error Message -->
					<div
						v-if="showErrorMessageOnInvalid"
						:class="{ 'min-h-[20px]' : showErrorMessage }"
					>
						<Transition name="fade-and-slide">
							<div
								v-if="showErrorMessage"
								class="py-1 pl-1 text-xs italic text-red-500"
							>
								{{ computedErrorMessage }}
							</div>
						</Transition>
					</div>

					<!-- Floating Label -->
					<label
						:for="id"
						:class="[
							'absolute pointer-events-none capitalize left-0 focus-within:left-0 text-gray-500 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:left-0 peer-focus:text-gray-500 peer-focus:text-sm',
							{ 'peer-placeholder-shown:left-2' : rounded },
							showFloatingLabelAboveInput ? '-mt-5 top-0' : 'peer-focus:-top-5 top-[10px]'
						]"
					>
						<span
							:class="[
								labelSize,
								labelClass
							]"
						>
							{{ label }}
						</span>
						<span
							v-if="required && showRequiredAstrisk && !readOnly"
							:class="[
								labelSize,
								'text-red-500 lowercase'
							]"
						>
							*
						</span>
					</label>
				</div>

				<!-- Default Label | Standard Text Input -->
				<input
					v-if="isStandardTextInput && !floatingLabel"
					:id="id"
					:type="dynamicInputType"
					class="w-full px-3 text-black placeholder-gray-500 rounded-lg h-11 focus:outline-none focus:ring-2"
					:class="[
						inputClass,
						borderValidationClass,
						readOnly ? 'border-b-0' : borderClass,
						{ 'bg-gray-200' : disabled },
						{ 'pl-8' : displayIcon && iconPosition == LEFT },
						`focus:ring-${ringColor}`,
						bgColor
					]"
					:autocomplete="autocomplete"
					:placeholder="placeholder"
					:maxlength="60"
					:value="modelValue"
					:disabled="disabled"
					:readonly="readOnly"
					:required="required"
					@input="updateValue"
					@blur="handleBlur"
					@focus="handleFocus"
					@keyup="handleKeyup"
				>

				<!-- Default Label | Telephone Input -->
				<input
					v-if="!floatingLabel && inputType === TEL"
					:id="id"
					:value="phoneNumber"
					type="tel"
					class="px-3 placeholder-gray-500 rounded-lg h-11 focus:outline-none focus:ring-2"
					:class="[
						inputClass,
						borderValidationClass,
						readOnly ? 'border-b-0' : borderClass,
						{ 'bg-gray-200' : disabled },
						{ 'pl-8' : displayIcon && iconPosition === LEFT },
						`focus:ring-${ringColor}`,
						bgColor
					]"
					:autocomplete="autocomplete"
					:placeholder="placeholder"
					:maxlength="60"
					:disabled="disabled"
					:readonly="readOnly"
					:required="required"
					@input="updateValue"
					@blur="handleBlur"
					@focus="handleFocus"
				>

				<!-- Default Label | Textarea Input -->
				<textarea
					v-if="inputType === TEXTAREA"
					:id="id"
					:rows="rows"
					:cols="cols"
					:aria-label="$t('aria.message')"
					class="px-3 placeholder-gray-500 border-gray-300 rounded-lg caret-mx-orange min-h-11 focus:outline-none focus:ring-2 focus:border-gray-300"
					:class="[
						inputClass,
						borderValidationClass,
						readOnly ? 'border-b-0' : borderClass,
						{ 'bg-gray-200' : disabled },
						{ 'pl-8' : displayIcon && iconPosition === LEFT },
						`focus:ring-${ringColor}`,
						bgColor
					]"
					:placeholder="placeholder"
					:maxlength="250"
					:value="modelValue"
					:disabled="disabled"
					:required="required"
					@input="updateValue"
					@blur="handleBlur"
				/>
				<!-- Icon -->
				<template v-if="displayIcon">
					<FormTextInputIcon :position="iconPosition">
						<slot name="icon">
							<IconSearch v-if="inputType === SEARCH" />
						</slot>
					</FormTextInputIcon>
				</template>
			</slot>
			<FormShowPasswordButton
				v-if="inputType === PASSWORD"
				v-model="showPassword"
				class="absolute right-1 top-2"
			/>
		</div>
		<!-- Default Label | Error Message -->
		<div
			v-if="showErrorMessageOnInvalid"
			:class="['h-[16px] pt-0.5 absolute w-full', errorMessagePosition]"
		>
			<Transition name="fade-and-slide">
				<slot name="accordion">
					<div
						v-if="showErrorMessage && !floatingLabel"
						:class="errorMessageClasses"
					>
						{{ computedErrorMessage }}
					</div>
				</slot>
			</Transition>
		</div>
		<div
			v-if="showInputInstruction"
			class="py-1"
		>
			<slot name="instruction">
				<div
					:class="[
						'pl-1 text-xs',
						isError ? 'text-red-500 italic' : 'text-gray-500'
					]"
					v-html="inputInstruction"
				/>
			</slot>
		</div>
	</div>
</template>

<script setup lang="ts">
import { toRefs, ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTrackEvent } from '@/composables/useEventTracking'
import { useErrorReporter } from '@/composables/useErrorReporter'

import { inputStateValidator, inputTypeValidator } from '@/validators/text-inputs.js'

import { ERROR, NONE } from '@/constants/form/states.js'
import {
	DATE,
	EMAIL,
	NUMBER,
	PASSWORD,
	SEARCH,
	TEL,
	TEXT,
	TEXTAREA,
	ZIP
} from '@/constants/form/text-inputs/types.js'
import {
	INPUT_BLUR,
	INPUT_FOCUS
} from '@/constants/gtm/events.js'
import type {
	InputFocusEvent,
	InputBlurEvent
} from '@/types/gtm/events'

import type { CountryCode } from '@/components/form/types'
type AsYouTypeType = typeof import('libphonenumber-js').AsYouType // TypeScript's type imports are guaranteed to be removed at runtime, so this should not affect bundle size.

let AsYouType: AsYouTypeType | undefined

const LEFT = 'left'
const RIGHT = 'right'
const { t } = useI18n({
	useScope: 'global'
})

const { reportError } = useErrorReporter(useBugsnag().notify)

const props = defineProps({
	state: {
		type: [ String, Boolean ],
		default: NONE,
		validator: inputStateValidator
	},
	label: {
		type: String,
		default: ''
	},
	floatingLabel: {
		type: Boolean,
		default: false
	},
	rounded: {
		type: Boolean,
		default: false
	},
	id: {
		type: String,
		required: true
	},
	modelValue: {
		type: String,
		required: true
	},
	placeholder: {
		type: String,
		required: true
	},
	disabled: {
		type: Boolean,
		default: false
	},
	required: {
		type: Boolean,
		default: false
	},
	inputClass: {
		type: String,
		default: ''
	},
	borderClass: {
		type: String,
		default: 'border-2 border-gray-300 focus:border-gray-300'
	},
	inputType: {
		type: String,
		default: TEXT,
		validator: inputTypeValidator
	},
	labelClass: {
		type: String,
		default: ''
	},
	labelSize: { // any tailwind text size class
		type: String,
		default: 'text-sm'
	},
	ringColor: { // any tailwind color
		type: String,
		default: 'mx-orange'
	},
	iconAlt: {
		type: String,
		default: ''
	},
	showIcon: {
		type: Boolean,
		default: false
	},
	iconLeft: {
		type: Boolean,
		default: true
	},
	iconRight: {
		type: Boolean,
		default: false
	},
	autocomplete: {
		type: String,
		default: 'on'
	},
	countryCode: {
		type: String as PropType <CountryCode>,
		default: 'US'
	},
	showErrorMessageOnInvalid: {
		type: Boolean,
		default: true
	},
	errorMessage: {
		type: String,
		default: ''
	},
	showRequiredAstrisk: {
		type: Boolean,
		default: true
	},
	bgColor: {
		type: String,
		default: ''
	},
	hideLabel: {
		type: Boolean,
		default: false
	},
	rows: {
		type: String,
		default: '5'
	},
	cols: {
		type: String,
		default: '0'
	},
	inputInstruction: {
		type: String,
		default: ''
	},
	readOnly: {
		type: Boolean,
		default: false
	},
	errorMessageAlwaysEnabled: {
		type: Boolean,
		default: false
	},
	errorMessagePosition: {
		type: String,
		default: ''
	},
	errorMessageClasses: {
		type: String,
		default: 'pl-1 text-xs italic text-red-500'
	}
})
const { modelValue, required, inputType, iconLeft, iconRight, countryCode, showIcon, showErrorMessageOnInvalid, errorMessage, floatingLabel, label, id, showRequiredAstrisk, labelClass, labelSize, ringColor, hideLabel, disabled, placeholder, bgColor, state, inputInstruction, readOnly, errorMessageAlwaysEnabled } = toRefs(props)

const emit = defineEmits([ 'update:modelValue', 'updated', 'input', 'validity-changed', 'blur', 'focus', 'keyup' ])
const updateValue = (event: Event) => {
	autoFillPresent.value = false
	emit('update:modelValue', (event.target as HTMLInputElement).value)
}

const display = ref<string | null>(null)
const isValid = ref(false)
const enableErrorMessage = ref(false)
const showPassword = ref(false)
const autoFillPresent = ref(false)

const dynamicInputType = computed(() => {
	if (inputType.value === PASSWORD && showPassword.value) {
		return TEXT
	}
	return inputType.value
})

const validateAsYouType = async (newValue: string) => {
	if (inputType.value !== TEL) { return } // stop if not a telephone input
	if (!AsYouType) {
		const lib = await import('libphonenumber-js')
		AsYouType = lib.AsYouType
	}
	const asYouType = new AsYouType(countryCode.value)
	if (!newValue?.length) { return }
	if (newValue?.length === 1) { return newValue }
	if (newValue?.length < 3) {
		display.value = newValue
		return
	}
	display.value = asYouType.input(newValue)
	const digits = asYouType.getNationalNumber()
	isValid.value = asYouType.isValid()
	emit('input', digits)
	emit('updated', asYouType)
	emit('validity-changed', isValid.value)
}

watch(modelValue, (newValue) => {
	validateAsYouType(newValue)
})

const phoneNumber = computed(() => {
	if (modelValue.value?.length < 3) { return modelValue.value }
	if (display.value) { return display.value }
	return modelValue?.value || ''
})

const borderValidationClass = computed(() => {
	if (!modelValue.value?.length) { return '' }
	return {
		'focus:border-red-500 border-red-500': isError.value
	}
})
const isError = computed(() => {
	if (typeof state.value === 'boolean') { return !state.value }
	return state.value === ERROR
})
const iconPosition = computed(() => {
	return iconLeft.value && !iconRight.value ? LEFT : RIGHT
})
const isStandardTextInput = computed(() => {
	const standardInputs = [ TEXT, SEARCH, DATE, NUMBER, PASSWORD, EMAIL, ZIP ]
	return standardInputs.includes(inputType.value)
})
const isTelephoneInput = computed(() => {
	return inputType.value === TEL
})
const displayIcon = computed(() => {
	if (isStandardTextInput.value || isTelephoneInput.value) {
		return showIcon.value
	}
	return false
})
const showErrorMessage = computed(() => {
	return isError.value && required.value && showErrorMessageOnInvalid.value && (enableErrorMessage.value || errorMessageAlwaysEnabled.value) && !readOnly.value
})
const showInputInstruction = computed(() => {
	return !!inputInstruction?.value
})

const computedErrorMessage = computed(() => {
	return errorMessage.value || t('default-error-message')
})

const showFloatingLabelAboveInput = computed(() => {
	return readOnly.value || modelValue.value?.length || autoFillPresent.value || inputType.value === DATE
})

function handleBlur (event: FocusEvent) {
	enableErrorMessage.value = true
	const trackedEvent: InputBlurEvent = {
		event: INPUT_BLUR,
		category: 'Forms',
		action: 'click',
		label: 'Input Blur',
		field_id: id.value
	}
	useTrackEvent(trackedEvent)
	emit('blur', event)
}
function handleFocus (event: FocusEvent) {
	const trackedEvent: InputFocusEvent = {
		event: INPUT_FOCUS,
		category: 'Forms',
		action: 'click',
		label: 'Input Focus',
		field_id: id.value
	}
	useTrackEvent(trackedEvent)
	emit('focus', event)
}
function handleKeyup (event: KeyboardEvent) {
	emit('keyup', event)
}

onMounted(() => {
	const element = document.getElementById(id.value)
	setTimeout(() => {
		try {
			if (element?.matches(':autofill')) {
				autoFillPresent.value = true
			}
		} catch (e) {
			reportError((e as Error).message || 'Error checking for autofill')
		}
	}, 300)
})

</script>
